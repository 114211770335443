$main-color: #0095FE;
$header-height: 50px;

/* Reset style */
* {
  box-sizing: border-box;
}
html, body, #root {
  position: relative;
  -webkit-text-size-adjust: none;
  width: 100vw;
  overflow: hidden;
  font-size: 16px;
}
img, svg {
  width: 100%;
}
input[type="text"], input[type="email"], textarea, select {
  width: 100%;
  position: relative;
  font-size: 16px;
  @apply bg-gray-100 rounded-lg p-2.5;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
small {
  color: #78909C;
  font-weight: normal;
}

.btn {
  @apply cursor-pointer font-bold py-3 px-5 text-center rounded-lg bg-blue-400 text-white border-0;
}

/* Material icon settings */
.material-icons {
  vertical-align: text-bottom;
  font-size: 1.2em;
  padding: 0 4px;
}

/* Scroll bar settings */
html {
  overflow-y: scroll;
}
html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-track {
  border-radius: 10px;
}
html::-webkit-scrollbar-thumb {
  background-color: rgba(106, 122, 145, 0.3);
  border-radius: 10px;
}

/* Table settings */
table {
  margin: 16px auto;
  display: block;
  border-collapse:collapse;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
table::-webkit-scrollbar {
    display:none;
}
thead, tbody {
  display: table;
  min-width: 100%;
}
tr {
  height: 2em;
}

/* header settings */
header {
  width: 100%;
}
header > div {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  height: $header-height;
}
header > div > a {
  float: left;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-align: center;
  padding: 0px 16px;
  line-height: $header-height;
  text-decoration: none;
}
header > div > a .material-icons {
  font-size: 1.2em;
}

/* wrapper settings */
.wrapper {
  background-color: #f6f7fc;
}

/* Markdown */
.md input[type="text"], .md textarea, .md select {
  @apply p-1.5 rounded-md;
}
