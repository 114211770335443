@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-height: 100vh;
  --base-color: #ffffff;
  --base-dark-color: #e5e5e5;
  --base-text-color: #343436;
  --main-color: #0095FE;
  --main-light-color: #95ccff;
  --main-dark-color: #008bfe;
  --main-text-color: #ffffff;
  --sub-color: #3e3e3e;
  --sub-dark-color: #313131;
  --sub-text-color: #ffffff;
  --acc-color: #f858a4;
  --acc-dark-color: #DB0A6F;
  --acc-text-color: #ffffff;
  --light-grey: #f6f7fc;
  --grey: #6a7a91;
  --header-height: 50px;
  --font-size: 18px;
  --border-width: 0px;
  --border-radius: 10px;
}



/* Footer */
footer {
  width: 100%;
  padding: 16px;
  background-color: var(--sub-color);
  color: var(--sub-text-color);
  font-size: .9em;
}

footer > div {
  padding: 16px;
}

footer > div > .content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  word-wrap: break-word;
  max-width: 900px;
  margin: 0 auto 24px;
}

@media (max-width:900px) {
  footer > div > .content {
    flex-direction: column;
  }
}

footer .heading {
  margin-bottom: 8px;
  font-weight: bold;
}

footer .links {
  align-items: flex-start;
  font-size: .8em;
  display: flex;
  flex-direction: column;
}

footer .links > * {
  padding: 4px;
}

footer a, footer a:link, footer a:visited, footer a:hover {
  white-space: nowrap;
}

/* main */
main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
  padding: 24px;
  min-height: calc(var(--app-height) - var(--header-height));
  background-color: var(--light-grey);
}

main > div{
  width: 100%;
  padding: 0 40px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
main > .wide {
  max-width: 1000px;
}
main > aside {
  width: 300px;
}

@media (max-width:700px) {
  main {
    padding: 0;
  }
  main > div{
    width: 100%;
    padding: 0 16px 24px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
  }
}

@media (max-width:1200px) {
  main > aside{
    display: none;
  }
}

main > div > button {
  float: right;
}

main > div > button > span {
  vertical-align: middle;
}

/* Card */
.card-list .card {
  margin: 16px 0 0;
  font-weight: bold;
  padding: 20px 24px;
  box-shadow: 0px 2px 8px #4385bb12;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--base-text-color);
  border-radius: var(--border-radius);
  background-color: var(--base-color);
  padding: 20px 40px;
}

@media (max-width:700px) {
  .card-list .card:first-child {
    margin: 0;
  }

  .card {
    padding: 20px 16px;
  }
}

/* Loader */
.spinner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: relative;
  display: flex;
  width: 70px;
  text-align: center;
  justify-content: space-around;
  align-items: center;
}
.spinner > div {
  width: 1rem;
  height: 1rem;
  background-color: var(--base-dark-color);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/* Markdown */
.md {
  color: rgba(0,0,0,0.82);
}
.md > div {
  width: 100%;
}
.md h1, .md h2, .md h3, .md h4 {
  font-weight: 400;
}
.md h1, .md h2, .md h3, .md h4, .md h5, .md p {
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
  position: relative;
}
.md h1 {
  font-size: 48px;
}
.md h2 {
  font-size: 1.5em;
  line-height: 1.6em;
  margin: 24px 0 16px;
  border-bottom: 3px solid var(--grey);
  font-weight: bold;
}
.md h2:after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 3px var(--main-color);
  bottom: -3px;
  width: 30%;
}
.md h3 {
  font-size: 1.3em;
  padding-left: 12px;
  border-left: 3px solid var(--main-color);
  font-weight: bold;
}
.md h4 {
    font-size: 1.5em;
}
.md h5 {
    font-size: 1.4em;
}
.md a {
    color: #0099ff;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}
.md ul, .md ol {
    padding: 0;
    margin: 0;
    list-style-position: inside;
}
.md li {
    line-height: 2em;
}
.md li ul, .md li ol {
    margin-left: 24px;
}
.md p, .md ul, .md ol {
    line-height: 2em;
}
.md pre {
    padding: 0px 24px;
    white-space: pre-wrap;
}
.md code {
    font-family: Consolas, Monaco, Andale Mono, monospace;
    line-height: 1.5;
    font-size: 13px;
}
.md aside {
    display: block;
    float: right;
    width: 390px;
}
.md blockquote {
  margin: 16px 0;
  padding: 16px 32px;
  opacity: .9;
  border-left: 3px solid var(--light-grey);
}
.md blockquote p {
  color: #666;
  margin-bottom: 0;
}
.md hr {
  width: 540px;
  text-align: left;
  margin: 0 auto 0 0;
  color: #999;
}
.md table {
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin: 16px auto 24px;
}
.md tbody {
  display: table;
}
.md table th,.md table td {
  padding: 1px;
  border: 2px solid var(--light-grey);
  background-color: var(--base-color);
  height: 2.5em;
}
.md table th > div,.md table td > div {
  padding: 0;
  display: flex;
  align-items: center;
}
.md table th > div.center,.md table td > div.center {
  justify-content: center;
}
.md table th > div.right,.md table td > div.right {
  justify-content: flex-end;
}
@media (max-width:512px) {
  .md .fill {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
  }
}
.md .radio, .md .checkbox {
  display: flex;
  justify-content: center;
  height: auto;
  border: none;
}
.md .radio > div, .md .checkbox > div {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 5px rgba(0,0,0,0.26);
  width: 100%;
  max-width: 350px;
  overflow: hidden;
}
.md .radio .item, .md .checkbox .item {
  position: relative;
}
.md .radio .item + .item, .md .checkbox .item + .item {
  border-top: 2px solid var(--light-grey);
}
.md .radio input, .md .checkbox input {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.md .radio label, .md .checkbox label {
  display: block;
  padding: 10px 4px 10px 48px;
  cursor: pointer;
}
.md .radio input:checked + label, .md .checkbox input:checked + label {
  background-color: var(--light-grey);
}
.md .journal th, .md .journal td {
  width: 25%;
}
.md .journal input, .md .journal select {
  width: 100%;
}
.md .md-box {
  position: relative;
  margin: 24px 0;
  padding: 24px 24px 0;
  border: solid 3px var(--main-light-color);
  border-radius: var(--border-radius);
}
.md .md-box-title {
  position: absolute;
  display: inline-block;
  top: -14px;
  left: 10px;
  padding: 0 8px;
  background: var(--base-color);
  color: var(--main-light-color);
  font-weight: bold;
}
.md .twitter-tweet {
  margin: 0 auto 24px !important;
}

.google_oauth {
  background-color: var(--base-color);
  color: var(--base-text-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-weight: 600;
  border-radius: var(--border-radius);
  height: 52px;
  margin: 0 8px 24px;
  font-size: .9rem;
}

.google_oauth {
  background-color: var(--base-color);
  color: var(--base-text-color);
  border: 4px solid #f6f7fc;
}

/* LP */
.lp {
  min-height: var(--app-height);
}
.lp .header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--base-text-color);
  background-color: var(--base-color);
}

.lp .header .logo {
  padding: 8px 16px;
  margin: 16px;
  font-size: 2em;
  font-weight: 700;
  font-family: "Inter","BlinkMacSystemFont",Arial,sans-serif;
}

.lp .header .login a {
  background-color: var(--main-color);
  color: var(--main-text-color);
  font-size: .875rem;
  line-height: 36px;
  padding: 0 16px;
  display: inline-flex;
  margin: 16px;
  border-radius: var(--border-radius);
  font-weight: 700;
}

.lp .top {
  min-height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: auto;
}

.lp .top .contents {
  padding: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lp .action-button {
  width: 100%;
}

.lp .to-contests {
  color: var(--main-text-color);
}

.lp .top .image {
  flex: 1;
}

@media (max-width:768px) {
  .lp .top {
    flex-direction: column;
  }
  .lp .top .contents {
    align-items: center;
  }
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: var(--main-color);
  opacity: .2;
}

.circle-progress {
  stroke: var(--main-color);
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-main-text {
  font-weight: 600;
  font-size: 2.5em;
  fill: var(--grey);
}

.circle-sub-text {
  font-weight: 600;
  opacity: .5;
}

.circular_progress_bar {
  display: flex;
  padding: 32px 0;
}

.barchart {
max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0px auto 32px;
  padding: 24px 0;
  border: solid 2px var(--light-grey);
  border-radius: var(--border-radius);
}

.barchart .text {
  color: var(--grey);
  font-weight: bold;
  padding: 0 24px 24px;
}

.barchart_rect {
  fill: var(--main-color);
}

.barchart_text {
  fill: #888;
  font-size: .8em
}

.status {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0px auto 32px;
  padding: 16px 24px;
  color: var(--grey);
  border: solid 2px var(--light-grey);
  border-radius: var(--border-radius);
  font-size: .9em;
}

.status > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: solid 2px var(--light-grey);
}

.status > div:last-child {
  border-bottom: none;
}

.correct {
  margin: 24px auto;
  padding: 24px 32px 16px;
  border-radius: var(--border-radius);
  background-color: #e3f2fd;
}

.correct .text {
  color: #90caf9;
  font-weight: bold;
  font-size: 1.2em;
}

.wrong {
  margin: 24px auto;
  padding: 24px 32px 16px;
  border-radius: var(--border-radius);
  background-color: #ffebee;
}

.wrong .text {
  color: #e57373;
  font-weight: bold;
  font-size: 1.2em;
}

@media (max-width:700px) {
  .correct, .wrong{
  padding: 24px 16px 16px;
  }
}
